export const filters = {
  permissions: {
    task: {},
    site: {},
    other: {}
  },
  task_types: [
    {label: 'Delivery', value: 'delivery'},
    {label: 'SSR', value: 'ssr'},
    {label: 'Installation', value: 'installation'}
  ],
  account_status: [
    { label: "Tous", value: "all" },
    { label: "Désactivé", value: "disabled" },
    { label: "Activé", value: "" },
    { label: "Supprimé", value: "deleted" },
  ],
  result_per_page: [
    { label: "15", value: 15 },
    { label: "40", value: 40 },
    { label: "100", value: 100 },
  ],
  cosite_types: [
    { label: "RADIO", value: "RADIO" },
    { label: "MSC", value: "MSC" },
    { label: "FO", value: "FO" },
    { label: "BSC", value: "BSC" },
    { label: "SDH", value: "SDH" },
    { label: "TRANS", value: "TRANS" },
    { label: "REPETEUR", value: "REPETEUR" },
    { label: "3G", value: "3G" },
    { label: "4G", value: "4G" },
  ],
  /*anomalies_status: [
    { label: "Resolved", value: "resolved" },
    { label: "Unresolved", value: "unresolved" },
  ],*/
  /*anomalies_types: [
    { label: "AC", value: "AC" },
    { label: "Batteries", value: "Batteries" },
    { label: "HSE", value: "HSE" },
    { label: "Electricity", value: "Electricity" },
    { label: "Power Supply", value: "Power Supply" },
    { label: "Shelter", value: "Shelter" },
    { label: "Autre", value: "Autre" },
  ],*/
  /*anomalies_types: [],
  anomalies_names: [],
  task_steps: [
    { label: "Electricité", value: "electricity" },
    { label: "Power Supply", value: "power" },
    { label: "Batteries", value: "batteries" },
    { label: "Shelter", value: "shelter" },
    { label: "AC", value: "ac" },
    { label: "BSS", value: "bss" },
    { label: "PDH-PAV-Redline", value: "pdh" },
    { label: "Alarms", value: "alarms" },
    { label: "GE", value: "ge" },
    { label: "Exterieur", value: "exterieur" },
  ],
  connectionInterval: [
    { label: "Tous", value: "" },
    { label: "Aujourd'hui", value: 24 },
    { label: "7 derniers jours", value: 24 * 7 },
    { label: "15 derniers jours", value: 24 * 15 },
    { label: "30 derniers jours", value: 24 * 30 },
    { label: "Jamais", value: 0 },
  ],
  site_classification: [
    { label: "Résidentiel", value: "Résidentiel" },
    { label: "Bureau", value: "Bureau" },
    { label: "Administratif", value: "Administratif" },
    { label: "Commercial", value: "Commercial" },
    { label: "Terrain", value: "Terrain" },
    { label: "Château d'eau", value: "Château d'eau" },
    { label: "Autre", value: "Autre" },
  ],
  site_status: [
    { label: "Tous", value: "all" },
    { label: "Active", value: "active" },
    { label: "Désactivé", value: "disabled" },
  ],
  cosite_types: [
    { label: "RADIO", value: "RADIO" },
    { label: "MSC", value: "MSC" },
    { label: "FO", value: "FO" },
    { label: "BSC", value: "BSC" },
    { label: "SDH", value: "SDH" },
    { label: "TRANS", value: "TRANS" },
    { label: "REPETEUR", value: "REPETEUR" },
    { label: "3G", value: "3G" },
    { label: "4G", value: "4G" },
  ],
  gpm_modes: [
    { label: 'All', value: ''},
    { label: 'Permanent', value: 'permanent'},
    { label: 'Hybride', value: 'hybride'},
    { label: 'Stand-by', value: 'stand-by'},
  ],
  gpm_zones: [
    { label: 'All', value: ''},
    { label: 'Environnement', value: 'Environnement'},
    { label: 'Nord', value: 'nord'},
    { label: 'Sud', value: 'Sud'},
    { label: 'Border', value: 'Border'},
  ],*/
  clusters: [],
  wilayas: [],
  agents: [],
  managers: [],
  cluster_managers: [],
  site_owners: [
    { label: "All", value: "" },
    // { label: "Djezzy", value: "djezzy" },
    // { label: "Mobilis", value: "mobilis" },
    { label: "Ooredoo", value: "ooredoo" },    
    { label: "GELOGIS", value: "gelogis" },
    { label: "Netfer", value: "netfer" },
    { label: "OTT", value: "ott" },
  ],
  type_site: [
    { label: "All", value: "" },
    { label: "Indoor", value: "indoor" },
    { label: "Outdoor", value: "outdoor" },
    { label: "Local Technique", value: "localtechnique" },
  ],
  /*typology_site: [
    { label: "All", value: "" },
    { label: "Green Field", value: "greenfield" },
    { label: "Rooftop", value: "rooftop" },
  ],
  support_antenne: [
    { label: "All", value: "" },
    { label: "Mat", value: "mat" },
    { label: "Pylone", value: "pylone" },
  ],
  type_ac: [
    { label: "All", value: "" },
    { label: "Monobloc", value: "monobloc" },
    { label: "Split", value: "split" },
  ],
  marques_ac: [],
  capacity_ac: [],
  marques_compteur: [],
  marques_batteries: [],
  marques_power: [],
  types_bss: [],
  problem_types: [],
  alarm_types: [],*/
  sites: [],

  eqp :[
    { label: "", value: "" },    
    { label: "ROOM", value: "ROOM" },    
    { label: "SHELTER", value: "SHELTER" },
    { label: "ROOM + OUTDOOR CABINET", value: "ROOM + OUTDOOR CABINET" },
    { label: "SHELTER + OUTDOOR CABINET", value: "SHELTER + OUTDOOR CABINET" },
    { label: "OUTDOOR CABINET", value: "OUTDOOR CABINET" },
    { label: "SMALL CELL", value: "SMALL CELL" },
  ],

  structure_type : [
    { label: "", value: "" },    
    { label: "GF SST 3L", value: "GF SST 3L" },    
    { label: "RT TOWER", value: "RT TOWER" },
    { label: "GF SST 4L", value: "GF SST 4L" },
    { label: "GF MONO", value: "GF MONO" },
    { label: "RT POLE", value: "RT POLE" },
    { label: "GF PALM", value: "GF PALM" },
    { label: "GF POLE", value: "GF POLE" },
    { label: "RT WMPOLE", value: "RT WMPOLE" },
    { label: "COW", value: "COW" },
    { label: "GF GMAST", value: "GF GMAST" },
    { label: "RT GMAST", value: "RT GMAST" },
    { label: "IBS", value: "IBS" },
    { label: "GF MAST", value: "GF MAST" },
    { label: "RT MAST", value: "RT MAST" },
    { label: "Small Cells", value: "Small Cells" },
  ],
  site_type :[
    { label: "", value: "" },    
    { label: "GREENFIELD", value: "GREENFIELD" },    
    { label: "ROOFTOP", value: "ROOFTOP" },
    { label: "INDOOR", value: "INDOOR" },
  ],

  indoor : [
    { label: "", value: "" },    
    { label: "IBS", value: "IBS" },
    { label: "Small Cells", value: "Small Cells" },
  ],
  rooftop : [
    { label: "", value: "" },      
    { label: "SST 3 LEGS", value: "SST 3 LEGS" },
    { label: "MONOPOLE", value: "MONOPOLE" },
    { label: "WALL MOUNTE", value: "WALL MOUNTE" },
    { label: "GUYED MAST", value: "GUYED MAST" },
    { label: "3L GUYED MAST", value: "3L GUYED MAST" },
  ],

  greebfield : [
    { label: "", value: "" },      
    { label: "SST 3 LEGS", value: "SST 3 LEGS" },
    { label: "SST 4 LEGS", value: "SST 4 LEGS" },
    { label: "MONOPOLE", value: "MONOPOLE" },
    { label: "PALM TREE", value: "PALM TREE" },
    { label: "GI POLE", value: "GI POLE" },
    { label: "MOBILE SHELTE", value: "MOBILE SHELTE" },
    { label: "GUYED MAST", value: "GUYED MAST" },
    { label: "3L GUYED MAST", value: "3L GUYED MAST" },
  ],

};

export default (state = filters, action) => {
  if (action.type === "SET_FILTERS") {
    // let newState = {
    // 	...state,
    // 	filters: {
    // 		...state.filters,
    // 		...action.payload
    // 	}
    // }

    // console.log("Filters updated")

    return { ...state, ...action.payload };
  }

  return state;
};
