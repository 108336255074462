import React, { Component } from "react";
import Select from "react-select";
import { connect } from "react-redux";

class TaskNew extends Component {
  
  submitTask = (e) => {
    e.preventDefault();
    this.props.submitTask(e);
  }

  render() {
    return (
      <React.Fragment>
        <div className={"container-header"}>Nouveau site</div>

        <form onSubmit={this.submitTask}>
          <div className={"row"}>
            <div className={"col-md-6 taskForm"}>

			        <div className={"form-group"}>
                <label>Site Owner </label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="site_owner"
                  options={this.props.filters.site_owners.filter(item => item.value !== "outsource" && item.value !== "")}
                />
              </div>

              <div className={"form-group"}>
                <label>Type site</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="type_site"
                  defaultValue={{ label: "site", value: 'site' }}
                  options={[
                    {value : 'site', label: 'site'}, 
                    {value : 'entrepot', label:'entrepot'},
                  ]}
                />
              </div>

			        <div className={"form-group"}>
                <label><b>Code site physique *</b></label>
                <input
				          required
                  type="text"
                  name="site_code"
                  className={"form-control"}
                />
              </div>


              <div className={"form-group"}>
                <label>Nom du site </label>
                <input
                  type="text"
                  name="site_name"
                  className={"form-control"}
                />
              </div>

              
              <div className={"form-group"}>
                <label>Wilaya *</label>
                <Select
                  classNamePrefix={"custom-select"}
                  name="site_address_wilaya"
                  options={this.props.filters.wilayas}
                />
              </div>

              <div className={"form-group"}>
                <label>Adresse du site </label>
                <input
                  type="text"
                  name="site_addess_info"
                  className={"form-control"}
                />
              </div>

              {/*<div className={"form-group"}>
                <label>Classification </label>

                <Select
                  classNamePrefix={"custom-select"}
                  name="site_classification"
                  options={this.props.filters.site_classification}
                />
              </div>

              <div className={"form-group"}>
                <label>Shelter </label>

                <Select
                  name="site_type"
                  options={this.props.filters.type_site.filter(
                    (item) => item.value
                  )}
                />
              </div>

              <div className={"form-group"}>
                <label>Support d'antenne </label>

                <Select
				classNamePrefix={"custom-select"}
				name="site_antenna_support"
				options={this.props.filters.support_antenne}
                />
                  </div>*/}

              <div className={"form-group"}>
                <button
                  type="submit"
                  className={"btn btn-success float-right"}
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({ filters: state.filters }))(TaskNew);
