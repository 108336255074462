import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";



i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          label_amount: "Amount",
          label_payment: "Payment",
          label_task: "Task",
          label_tasks: "Tasks",
          label_agent: "Agent",
          label_agents: "Agents",
          label_address: "Address",
          label_datetime: "Date & time",
          task_edit: "Edit task",
          task_start_date: "Start date",
          task_end_date: "End date",
          label_start: "End",
          label_end: "Start",
          task_assign: "Assign",
          task_name: "Task name",
          task_type: "Task type",
          task_assign_to_agent: "Assign to agent",
          label_status: "Status",
          label_client_name: "Client name",
          label_edit: "Edit",
          button_new_task: "Add new task",
          button_new_agent: "Add new agent",
          button_map_view: "Map view",
          button_add_task: "Add task",
          label_details: "Details",
          label_delete: "Delete",
          user_phone: "Phone",
          user_username: "Username",
          user_email: "Email",
          account_overview: "Overview",
          account_account: "Account",
          account_activity: "Activity",
          account_track: "Track",
          account_billing: "Billing",
          account_securirity: "Security",
          user_password: "Password",
          user_firstname: "First name",
          user_lastname: "Last name",
          agent_online: "Online",
          agent_offline: "Offline",
          agent_onduty: "On duty",
          task_ongoing: "Ongoing",
          agent_details: "Agent details",
          recent_activity: "Recent Activity",
          label_activity: "Activity",
          label_name: "Name",
          label_agents_all: "All",
          label_new_agent: "New agent",
          account_information: "Account information",
          personal_information: "Personal information",
          user_phonenumber: "Phone number",
          button_create: "Create",
          label_notifications: "Notifications",
          sms_notifications: "SMS Notifications",
          email_notifications: "Email Notifications",
          menu_my_agents: "My Agents",
          menu_add_agent: "Add new agent",
          menu_view_tasks: "View tasks",
          menu_create_task: "Create new task",
          menu_account: "Account",
          menu_personal: "Personal",
          menu_plan: "Plan",
          menu_notifications: "Notifications",
          menu_security: "Security",
          menu_dashboard: "Dashboard",
          label_types: "Types",
          label_type: "Type",
          signed_in_as: "Signed in as",
          my_account: "My account",
          change_my_password: "Change my password",
          logout: "Logout",
          help: "Help",
          task_completed: "Completed",
          kms_tracked: "Kilometers tracked",
          status_unassigned: "Unassigned",
          status_assigned: "Assigned",
          status_accepted: "Accepted",
          status_completed: "Completed",
          status_failed: "Failed",
          status_declined: "Declined",
          status_started: "Started",
          status_canceled: "Canceled",
          status_arrived: "Arrived",
          special_instructions: "Special instructions",
          current_status: "Current status",
          agent_status: "Agent status",
          button_save: "Save",
          filter_show_onduty: "Show on duty only",
          filter_show_all: "Show All",
          filter_show_available: "Show available",
          agents_map: "Agent map",
          label_account: "account",
          create_new_account: "Create new account",
          label_signin: "Sign in",
          confirmation_code: "Confirmation code",
          password_recovery: "Password recovery",
          label_register: "Register",
          label_joinus: "Join us",
          label_login: "Login",
          label_home: "Home",
          empty_tasks: "You don't have any task.",
          empty_agents: "You don't have any agent.",
          label_subscription: "Subscription",
          label_subscription_period: "Subscription period",
          label_method: "Method",
          label_method_vir_ccp: "CCP Transfer",
          label_method_vir_bank: "Bank Transfer",
          label_method_vir_check: "Check",
          label_payment_type_prepaid: "Refill",
          label_payment_type_subscribe: "Subscription",
          label_plan_type_prepaid: "Refill",
          label_plan_type_subscribe: "Subscription",
          label_plan_type_free: "Free trial",
          label_upgrade: "Upgrade",
          label_payment_status_confirmed: "Approved",
          label_payment_status_rejected: "Rejected",
          label_payment_status_pending: "Pending",
          button_approve: "Approve",
          button_reject: "Reject",
          label_bill_payment: "Paiement de facture",
          label_expiration_date: "Expiry date",
          label_creation_date: "Creation date",
          label_sites: "Sites",
          label_sites_list: "Sites list",
          label_new_site: "New site",
          label_new_warehouse: "New warehouse",
          label_pm: "PMs",
          label_pm_list: "PM list",
          label_managers: "Managers"

        }
      },
      fr: {
        translations: {
          label_managers: "Managers",
          label_bill_payment: "Bill payment",
          label_amount: "Montant",
          label_subscription: "Abonnement",
          label_subscription_period: "Période de abonnement",
          label_payment: "Paiement",
          label_task: "Tâche",
          label_tasks: "Tâches",
          label_agent: "Agent",
          label_agents: "Agents",
          label_address: "Adresse",
          label_datetime: "Date & heure",
          label_method: "Méthode",
          task_edit: "Modifier une tâche",
          task_start_date: "Date début",
          task_end_date: "Date fin",
          label_start: "Début",
          label_end: "Fin",
          task_assign: "Assigner",
          task_name: "Nom de la tâche",
          task_type: "Type de tâche",
          task_assign_to_agent: "Assigner à un agent",
          label_status: "Statut",
          label_client_name: "Nom client",
          label_edit: "Modifier",
          button_new_task: "Ajouter une nouvelle tâche",
          button_new_agent: "Ajouter un nouvel agent",
          button_map_view: "Vue sur Carte",
          button_add_task: "Ajouter",
          label_details: "Détails",
          label_delete: "Supprimer",
          user_phone: "Téléphone",
          user_username: "Nom d'utilisateur",
          user_email: "E-mail",
          account_overview: "Aperçu",
          account_account: "Compte",
          account_activity: "Activité",
          account_track: "Tracer",
          account_billing: "Facturation",
          account_securirity: "Sécurité",
          user_password: "Mot de passe",
          user_firstname: "Prénom",
          user_lastname: "Nom",
          agent_online: "En ligne",
          agent_offline: "Hors ligne",
          agent_onduty: "Occupé",
          agent_details: "Détail agent",
          task_ongoing: "En cours",
          recent_activity: "Activités récentes",
          label_activity: "Activité",
          label_name: "Nom",
          label_agents_all: "Tous",
          label_new_agent: "Nouvel agent",
          account_information: "Informations du compte",
          personal_information: "Informations personnels",
          user_phonenumber: "Numéro de téléphone",
          button_create: "Créer",
          label_notifications: "Notifications",
          sms_notifications: "Notification par SMS",
          email_notifications: "Notification par mail",
          menu_my_agents: "Mes agents",
          menu_add_agent: "Ajouter un agent",
          menu_view_tasks: "Voir mes tâches",
          menu_create_task: "Créer une tâche",
          menu_account: "Compte",
          menu_personal: "Personnel",
          menu_plan: "Mon offre",
          menu_notifications: "Notifications",
          menu_security: "Sécurité",
          menu_dashboard: "Tableau de board",
          label_types: "Types",
          label_type: "Type",
          signed_in_as: "Connecté en tant que",
          my_account: "Mon compte",
          change_my_password: "Changer mon mot de passe",
          logout: "Deconnexion",
          help: "Aide",
          task_completed: "Terminé",
          kms_tracked: "Kilomètres",
          status_unassigned: "Non assignée",
          status_assigned: "Assignée",
          status_accepted: "Acceptée",
          status_completed: "Terminée",
          status_failed: "Echouée",
          status_declined: "Rejetée",
          status_started: "Commencée",
          status_canceled: "Annulée",
          status_arrived: "Arrivé",
          special_instructions: "Instruction spécial",
          current_status: "Status actuel",
          agent_status: "Status de l'agent",
          button_save: "Sauvegarder",
          filter_show_onduty: "Occupés",
          filter_show_all: "Tous",
          filter_show_available: "Disponible",
          agents_map: "Carte d'agent",
          label_account: "Compte",
          create_new_account: "Nouveau compte",
          label_signin: "Se connecter",
          confirmation_code: "Code de confirmation",
          pm_information: "Planifer une tache ",
          password_recovery: "Récuperation de mot de passe",
          label_register: "S'inscrire",
          label_joinus: "Nous rejoindre",
          label_login: "Connexion",
          label_home: "Accueil",
          empty_tasks: "Vous n'avez aucune tâche.",
          empty_agents: "Vous n'avez ajouté aucun agent",
          label_method_vir_ccp: "Virement CCP",
          label_method_vir_bank: "Virement Bancaire",
          label_method_vir_check: "Chèque",
          label_payment_type_prepaid: "Recharge",
          label_payment_type_subscribe: "Abonnement",
          label_plan_type_prepaid: "Prépayé",
          label_plan_type_subscribe: "Abonnement",
          label_plan_type_free: "Essai gratuit",
          label_upgrade: "Mise à niveau",
          label_payment_status_confirmed: "Confirmé",
          label_payment_status_rejected: "Refusé",
          label_payment_status_pending: "En Attente",
          button_approve: "Confirmer",
          button_reject: "Refuser",
          label_expiration_date: "Date d'expiration",
          label_creation_date: "Date de creation",
          label_sites: "Sites",
          label_sites_list: "Liste des sites",
          label_new_site: "Nouveau site",
          label_new_warehouse: "Nouveau entrepot",
          label_pm: "PM",
          label_pm_list: "Liste des PM",
        }
      }
    },
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],

    },
    fallbackLng: "fr",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;