import React from "react"
import Select from "react-select";
import { connect } from "react-redux";
import CoSiteList from "./CoSiteList";

class General extends React.Component {
    render() {

        const site = this.props.site || {};

        console.log(this.props);

        return (<>
        
        <div className={"row"}>

            <div className={"col-md-6"}>
                <h3>Info</h3>
                
                <div className={"form-group"}>
                    <label>Site Code (physique)</label>
                    <input type={"text"} className={"form-control"} defaultValue={site.site_code} />
                </div>

                <div className={"form-group"}>
                    <label>Code Facturation Delivery</label>
                    <input type={"text"} className={"form-control"} defaultValue={site.site_code_facture} />
                </div>


                <div className={"form-group"}>
                    <label>Site name</label>
                    <input type={"text"} className={"form-control"} defaultValue={site.site_name} />
                </div>

                <div className={"form-group"}>
                    <label>Wilaya</label>
                    <Select 
                        options={this.props.filters.wilayas}
                        defaultValue={{label: site.site_wilaya, value: site.site_wilaya}}
                        classNamePrefix={"custom-select"}
                    />
                    
                </div>

                <div className={"form-group"}>
                    <label>Address</label>
                    <input type={"text"} className={"form-control"} defaultValue={site.site_address} />
                </div>


                <div className={"form-group"}>
                    <label>Owner</label>
                    <Select 
                        options={this.props.filters.site_owners.filter(item => item.value !== "outsource" && item.label !== "All")}
                        defaultValue={{label: site.site_owner, value: site.site_owner}}
                        classNamePrefix={"custom-select"}
                    />
                </div>

                <div className={"form-group"}>
                    <label>Ingénieur responsable</label>
                    <Select 
                        options={this.props.filters.agents}
                        defaultValue={{label: site.site_owner_agent, value: site.site_owner_agent_id}}
                        classNamePrefix={"custom-select"}
                    />
                </div>

                <div className={"clearfix mt-10"}></div>

                

            </div>


            <div className={"col-md-6 "}>

                {site.site_id && (<CoSiteList siteid={site.site_id} site={site} />)}

                <div className={"clearfix my-4"}></div>

                <h3>Notes</h3>
                <div className={"ctable"}>


                <div className={"row head-titles ctable-row"}>
                    <div className={"col-md-8"}>
                            Note
                    </div>
                    <div className={"col-md-4"}>
                            Auteur
                    </div>
                </div>
                
                {site.notes && site.notes.map(note => (
                    <div className={"row ctable-row"}>
                            <div className={"col-md-8"}>
                                    {note.content}
                            </div>
                            <div className={"col-md-4"}>
                                    {note.created_at}<br />
                                    {note.created_by}
                            </div>
                    </div>
                ))}

                </div>

                
            </div>

        </div>
        </>)
    }
}

export default connect(state => ({filters: state.filters }) )(General);